import React, { Component, useState, useEffect } from "react";
import Header1 from "../../components/header1/header1";
import Paragraph from "../../components/paragraph/paragraph";
import Nav from "../../components/navbar/navbar";
import Grid from "@material-ui/core/Grid";
import Footer from "../../components/footer/footer";
import Hidden from "@material-ui/core/Hidden";
import Carousel from "react-elastic-carousel";

export default function Results() {
  return (
    <div>
      <Nav selected="Projects" />
      <div style={{ width: "100%", height: "30vh", position: "relatives" }}>
        <img
          src={require("./../../assets/tricity/tricityHeader.jpg")}
          style={{
            height: "30vh",
            width: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "30vh",
            display: "flex",
          }}
        >
          <Grid
            container
            style={{
              alignItems: "center",
            }}
          >
            <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <Header1
                style={{
                  color: "white",
                  fontSize: "5vh",
                }}
              >
                Tri-City
              </Header1>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Grid container>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1}></Grid>

          <Grid
            container
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paragraph style={{ margin: 0 }}>
                <b>Type:</b> Improved Forest Management
                <br />
                <br />
                <b>Standard:</b> American Carbon Registry
                <br />
                <br />
              </Paragraph>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paragraph style={{ margin: 0 }}>
                <b>Area:</b> 13,536 acres
                <br />
                <br />
                <b>Developer:</b> Bluesource
                <br />
                <br />
              </Paragraph>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Paragraph style={{ margin: 0 }}>
                <b>Location:</b> Northern Massachusetts
              </Paragraph>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Line Break
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <hr />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Summary Section
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container style={{ paddingTop: 30, paddingBottom: 30 }}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Header1
                  style={{
                    color: "#22422B",
                  }}
                >
                  Summary
                </Header1>
                <Paragraph>
                  The Tri-City project is an Improved Forest Management project
                  comprised of mixed hardwoods and spruce-fir forests in
                  southwestern Massachusetts. Three cities in Massachusetts —
                  Holyoke, Westfield, and West Springfield—launched a joint
                  project on public land. The cities are under pressure to
                  generate increased revenue from the forests, and this carbon
                  project enables them to do so without resorting to aggressive
                  timber harvesting.
                </Paragraph>
              </Grid>
              <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>
              <Hidden smDown>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <img
                  src={require("./../../assets/tricity/tricitySummary.jpg")}
                  style={{
                    width: "100%",
                    maxHeight: "40vh",
                    objectFit: "cover",
                  }}
                />
                <Paragraph>
                  Many rivers weave their way through the property.
                </Paragraph>
              </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Line Break
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <hr />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Cobenefits Section
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <Grid container style={{ paddingTop: 30, paddingBottom: 30 }}>
              <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                <Header1
                  style={{
                    color: "#22422B",
                  }}
                >
                  Co-Benefits
                </Header1>
                <Paragraph>
                  This project protects habitat used by a myriad of species,
                  including foxes, bears, deer, moose and bobcats. It also
                  protects an essential watershed for people in Northern
                  Massachusetts, filtering the water flowing through several
                  rivers on the property.
                </Paragraph>
              </Grid>
              <Grid item xs={0} sm={0} md={2} lg={2} xl={2}></Grid>

              <Hidden smDown>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <img
                  src={require("./../../assets/tricity/tricityMoose.jpg")}
                  style={{
                    width: "100%",
                    objectFit: "cover",
                  }}
                />
                <Paragraph>
                  Moose are the largest deer on the planet, and this project
                  protects their habitat.
                </Paragraph>
              </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/* 
          Line Break
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
          <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
            <hr />
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          {/*
          Bottom Images
          */}
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />

          <Grid
            container
            xs={10}
            sm={10}
            md={10}
            lg={10}
            xl={10}
            style={{
              paddingTop: 20,
              paddingBottom: 40,
            }}
          >
            <Hidden smDown>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingRight: 20 }}
            >
              <img
                src={require("./../../assets/tricity/tricityBottom1.png")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <img
                src={require("./../../assets/tricity/tricityBottom2.jpg")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              style={{ paddingLeft: 20 }}
            >
              <img
                src={require("./../../assets/tricity/tricityBottom3.jpg")}
                style={{
                  width: "100%",
                  height: "40vh",
                  objectFit: "cover",
                }}
              />
            </Grid>
            </Hidden>
            <Hidden mdUp>
            <Header1
                style={{
                  color: "#22422B",
                }}
              >
                Project Gallery
              </Header1>
              <Carousel
                showArrows={false}
                pagination={false}
                style={{ height: "40vh" }}
              >
                <img
                    src={require("./../../assets/tricity/tricityBottom1.png")}
                    style={{
                      width: "100%",
                      maxHeight: "40vh",
                      objectFit: "cover",
                    }}
                  />
                <img
                  src={require("./../../assets/tricity/tricityBottom2.jpg")}
                  style={{
                    width: "100%",
                    height: "40vh",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={require("./../../assets/tricity/tricityMoose.jpg")}
                  style={{
                    width: "100%",
                    height: "40vh",
                    objectFit: "cover",
                  }}
                />
                <img
                  src={require("./../../assets/tricity/tricitySummary.jpg")}
                  style={{
                    width: "100%",
                    height: "40vh",
                    objectFit: "cover",
                  }}
                />
              </Carousel>
            </Hidden>
          </Grid>
          <Grid item xs={1} sm={1} md={1} lg={1} xl={1} />
        </Grid>
      </div>
      <Footer />
    </div>
  );
}
